import React from 'react';
import { AnimatePresence } from "framer-motion"

export const wrapPageElement = ({ element }) => {
  return (
    <AnimatePresence key="animate-presence" mode='wait'>
      {element}
    </AnimatePresence >
  )
}
